import type { InjectionKey } from 'vue'

// models
import {
  CloudStoreItem,
  ItemPurchased,
  ItemPurchasedWithProfile,
  getItemsResponse,
  getItemsPublishedRecentResponse,
  GetItemsRequest,
  GetItemsResponse,
  GetItemsPublishedRecentRequest,
  GetItemsPublishedRecentResponse,
  SortType,
  CloudStoreSortData,
  OBJECT_TYPE,
  ObjectType,
  OrderItem,
  OrderItemWithProfile,
  OrderItemDetail,
  OrderItemDetailWithProfile,
  OrderItemHistory,
  LIST_TYPE,
  ForcedPrivateItem,
  OrderFreeItemHistoryDetail,
  OrderFreeItemDetailWithProfile,
  Item,
  BundlesType,
  BUNDLES_TYPE,
} from '@/models/cloudstore'
import { MyVketEvent } from '@/models/myVketEvent'

// models
import {
  GetProfileListResponse,
  getProfileListResponse,
} from '@/models/profiles'

// repository
import {
  getItemsLikesResponse,
  GetItemsLikesResponse,
  DeleteItemLikeResponse,
  GetEventItemsResponse,
  PostItemPurchaseResponse,
  PutItemLikeResponse,
  deleteItemLikeResponse,
  getEventItemsResponse,
  postItemPurchaseResponse,
  putItemLikeResponse,
  GetItemPublishedDetailRequest,
  GetItemPublishedDetailResponse,
  GetItemsPublishedRequest,
  GetItemsPublishedRequestWithPager,
  GetItemsPublishedResponse,
  getItemPublishedDetailResponse,
  getItemsPublishedResponse,
  getItemByAssetIdResponse,
  GetItemByAssetIdResponse,
  getPurchaseHistoriesResponse,
  GetPurchaseHistoriesResponse,
  PatchItemRequest,
  patchItemResponse,
  PatchItemResponse,
  PostItemRequest,
  postItemResponse,
  PostItemResponse,
  PutItemRequest,
  putItemResponse,
  PutItemResponse,
  GetItemDetailRequest,
  getItemDetailResponse,
  GetItemDetailResponse,
  GetItemsPopularRequest,
  getItemsPopularResponse,
  GetItemsPopularResponse,
  GetPurchaseHistoriesDetailRequest,
  getPurchaseHistoriesDetailResponse,
  GetPurchaseHistoriesDetailResponse,
  getPurchaseHistoriesFreeDetailResponse,
  GetPurchaseHistoriesFreeDetailResponse,
  getForcedPrivateItemsResponse,
  GetForcedPrivateItemsResponse,
  GetPurchaseHistoriesFreeDetailRequest,
} from '@/composables/repository/useCloudStoreRepository'
import { isFetchError } from '@/composables/repository/useOhmyfetch'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'

// modules
import { existResponse } from '@/utils/zod'

export const useCloudStore = () => {
  const repositoryFactory = useRepositoryFactory()
  const i18n = useI18n()

  const popularItemLimit = 10
  const officialItemLimit = 20
  const avatarItemLimit = 20
  const furnitureItemLimit = 20
  const pickupItemLimit = 10
  const vketBoothMarcheItemLimit = 20
  const avatarPartLimit = 10
  // ページャ用に利用
  const limit = ref(30)
  const offset = ref(0)
  const total = ref(0)

  // フィルタリング、ソート用
  const sortType = ref<SortType>('newer')
  const searchText = ref('')

  type CloudStoreStateType = {
    assetList: ItemPurchasedWithProfile[]
    assetDetail: GetItemPublishedDetailResponse | null
    myAssetDetail: CloudStoreItem | null
    itemsLikesList: ItemPurchasedWithProfile[]
    purchaseHistoriesList: OrderItemWithProfile[]
    purchaseHistoriesDetail: OrderItemDetail | null
    purchaseHistoriesDetailItems: OrderItemDetailWithProfile[]
    purchaseHistoriesFreeDetail: OrderFreeItemHistoryDetail | null
    purchaseHistoriesFreeDetailWithProfile: OrderFreeItemDetailWithProfile | null
    popularList: ItemPurchasedWithProfile[]
    officialList: ItemPurchasedWithProfile[]
    bundleList: ItemPurchasedWithProfile[]
    avatarList: ItemPurchasedWithProfile[]
    housingList: ItemPurchasedWithProfile[]
    pickupList: ItemPurchasedWithProfile[]
    vketBoothMarcheList: ItemPurchasedWithProfile[]
    avatarPartList: ItemPurchasedWithProfile[]
    otherList: ItemPurchasedWithProfile[]
    categoryList: ItemPurchasedWithProfile[]
    /** ログインしているユーザーのアイテムで強制非公開になっているアイテム */
    forcedPrivateItems: ForcedPrivateItem[]
  }

  const state = useState<CloudStoreStateType>('cloud_store_state', () => ({
    assetList: Array(limit.value).fill(null),
    assetDetail: null,
    myAssetDetail: null,
    itemsLikesList: [],
    purchaseHistoriesList: [],
    purchaseHistoriesDetail: null,
    purchaseHistoriesDetailItems: [],
    purchaseHistoriesFreeDetail: null,
    purchaseHistoriesFreeDetailWithProfile: null,
    popularList: Array(popularItemLimit).fill(null),
    officialList: Array(officialItemLimit).fill(null),
    bundleList: [],
    avatarList: Array(avatarItemLimit).fill(null),
    housingList: Array(furnitureItemLimit).fill(null),
    pickupList: Array(pickupItemLimit).fill(null),
    vketBoothMarcheList: Array(vketBoothMarcheItemLimit).fill(null),
    avatarPartList: Array(avatarPartLimit).fill(null),
    otherList: [],
    categoryList: [],
    forcedPrivateItems: [],
  }))

  const sortItems: CloudStoreSortData[] = [
    {
      value: 'newer',
      text: i18n.t('sort.cloud-store.newer'),
    },
    {
      value: 'older',
      text: i18n.t('sort.cloud-store.older'),
    },
    {
      value: 'popular',
      text: i18n.t('sort.cloud-store.popular'),
    },
    {
      value: 'likes',
      text: i18n.t('sort.cloud-store.liked'),
    },
    // NOTE: 有料が実装されたら追加
    // {
    //   value: 'price_desc',
    //   label: '価格が高い順',
    //   labelEn: 'Price Desc',
    // },
    // {
    //   value: 'price_asc',
    //   label: '価格が安い順',
    //   labelEn: 'Price Asc',
    // },
  ]

  const cloudStoreRepository = repositoryFactory.get('cloudStore')
  const profileRepository = repositoryFactory.get('profile')

  // ユーザープロフィールのデータを追加した販売商品一覧を取得
  const getItemsWithProfile = async (
    items: ItemPurchased[]
  ): Promise<ItemPurchasedWithProfile[]> => {
    const vketIds = items
      .map((item) => item.item.user?.vketId)
      .filter((vketId): vketId is string => vketId !== undefined)
    if (vketIds.length === 0) return []
    const response = await profileRepository.post.getProfilesList({ vketIds })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetProfileListResponse>(getProfileListResponse, response)
    ) {
      console.error('An API response is different.')
    }

    const profiles = response.profiles
    const result = items.map((item) => {
      const profile = profiles.find(
        (_profile) => _profile.vketId === item.item.user?.vketId
      )
      return {
        ...item,
        profile: profile || null,
      }
    })
    return result
  }

  // [販売者用] 自分の登録した販売商品一覧
  const getItems = async (params: GetItemsRequest) => {
    try {
      const { data } = await useAsyncData('getItems', () =>
        cloudStoreRepository.get.getItems(params)
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (!existResponse<GetItemsResponse>(getItemsResponse, data.value)) {
        console.error('An API response is different.')
      }

      return data.value
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  // [販売者用] 自分の登録商品データ詳細
  const getItemDetail = async (params: GetItemDetailRequest) => {
    try {
      const response = await cloudStoreRepository.get.getItemDetail(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemDetailResponse>(getItemDetailResponse, response)
      ) {
        console.error('An API response is different.')
      }

      state.value.myAssetDetail = response.item
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [販売者用] 入手・購入もしくはアップロードした商品データ詳細
  const getItemByAssetId = async (assetId: number) => {
    try {
      const response = await cloudStoreRepository.get.getItemByAssetId(assetId)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemByAssetIdResponse>(
          getItemByAssetIdResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }
      state.value.myAssetDetail = response.item
      return response.item
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [購入者用] 公開商品一覧取得
  const getItemsPublished = async (
    params: GetItemsPublishedRequest & { type?: string }
  ) => {
    try {
      const response = await cloudStoreRepository.get.getItemsPublished(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemsPublishedResponse>(
          getItemsPublishedResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      if (params.limit) limit.value = params.limit
      if (params.offset) offset.value = params.offset
      total.value = response.itemCount
      const itemsWithProfile = await getItemsWithProfile(response.items)
      state.value.assetList = itemsWithProfile

      if (params.type === LIST_TYPE.OFFICIAL)
        state.value.officialList = itemsWithProfile
      if (params.type === LIST_TYPE.BUNDLE)
        state.value.bundleList = itemsWithProfile
      if (params.type === LIST_TYPE.AVATAR)
        state.value.avatarList = itemsWithProfile
      if (params.type === LIST_TYPE.HOUSING)
        state.value.housingList = itemsWithProfile
      if (params.type === LIST_TYPE.PICKUP)
        state.value.pickupList = itemsWithProfile
      if (params.type === LIST_TYPE.VKETBOOTHMARCHE)
        state.value.vketBoothMarcheList = itemsWithProfile
      if (params.type === LIST_TYPE.AVATARPART)
        state.value.avatarPartList = itemsWithProfile
      if (params.type === LIST_TYPE.OTHER)
        state.value.otherList = itemsWithProfile
      if (params.type === LIST_TYPE.CATEGORY)
        state.value.categoryList = itemsWithProfile

      return itemsWithProfile
    } catch (e) {
      // 空の配列を格納
      if (params.type === LIST_TYPE.OFFICIAL) state.value.officialList = []
      if (params.type === LIST_TYPE.AVATAR) state.value.avatarList = []
      if (params.type === LIST_TYPE.HOUSING) state.value.housingList = []
      if (params.type === LIST_TYPE.PICKUP) state.value.pickupList = []
      if (params.type === LIST_TYPE.VKETBOOTHMARCHE)
        state.value.vketBoothMarcheList = []
      if (params.type === LIST_TYPE.AVATARPART) state.value.avatarPartList = []
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  // [購入者用] 最近見た商品一覧取得
  const getItemsPublishedRecent = async (
    params: GetItemsPublishedRecentRequest
  ) => {
    try {
      const { data } = await useAsyncData('getItemsPublishedRecent', () =>
        cloudStoreRepository.get.getItemsPublishedRecent(params)
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemsPublishedRecentResponse>(
          getItemsPublishedRecentResponse,
          data.value
        )
      ) {
        console.error('An API response is different.')
      }

      return data.value
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  // [購入者用] 人気アイテム一覧取得
  const getItemsPopular = async (params: GetItemsPopularRequest) => {
    try {
      const response = await cloudStoreRepository.get.getItemsPopular(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemsPopularResponse>(
          getItemsPopularResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }
      const itemsWithProfile = await getItemsWithProfile(response.items)
      state.value.popularList = itemsWithProfile
      return itemsWithProfile
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  // [購入者用] 公開商品データ詳細取得
  const getItemPublishedDetail = async (
    params: GetItemPublishedDetailRequest
  ) => {
    try {
      const response = await cloudStoreRepository.get.getItemPublishedDetail(
        params
      )

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemPublishedDetailResponse>(
          getItemPublishedDetailResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }
      state.value.assetDetail = response
      return state.value.assetDetail
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [購入者用] イベント関連アイテム一覧取得
  const getEventItems = async (eventId: MyVketEvent['id']) => {
    try {
      const { data } = await useAsyncData('getEventItems', () =>
        cloudStoreRepository.get.getEventItems(eventId)
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetEventItemsResponse>(
          getEventItemsResponse,
          data.value.items
        )
      ) {
        console.error('An API response is different.')
      }

      const itemsWithProfile = await getItemsWithProfile(data.value.items)
      state.value.assetList = itemsWithProfile
      return itemsWithProfile
    } catch (error) {
      if (isFetchError(error)) {
        console.error(error)
        return null
      }
      throw error
    }
  }

  // [購入者用] 関連するセットアイテムの一覧取得
  const getItemsBundle = async (item: Item) => {
    const type = item.bundlesType

    // セットアイテムでない場合、関連セットアイテムは存在しない
    if (type === BUNDLES_TYPE.NONE) {
      state.value.bundleList = []
      return state.value.bundleList
    }

    // 親アイテムだった場合、子アイテムを取得
    if (type === BUNDLES_TYPE.ITEM_BUNDLES && item.additionalItems) {
      state.value.bundleList = await getItemsWithProfile(
        item.additionalItems.map((_item) => ({ item: _item }))
      )
    }

    // 子アイテムだった場合、親アイテムと、親アイテム経由で他の子アイテムを取得
    if (type === BUNDLES_TYPE.INCLUDE_ITEM) {
      const _parentItem = item.bundledByItem
      if (!_parentItem) {
        state.value.bundleList = []
        return state.value.bundleList
      }

      try {
        const parentItem =
          await cloudStoreRepository.get.getItemPublishedDetail({
            id: _parentItem.id,
          })

        if (!parentItem) {
          throw new Error('response is empty.')
        }

        if (
          !existResponse<GetItemPublishedDetailResponse>(
            getItemPublishedDetailResponse,
            parentItem
          )
        ) {
          console.error('An API response is different.')
        }

        // 親アイテムの子アイテムを取得
        if (!parentItem.item.additionalItems) {
          state.value.bundleList = []
          return state.value.bundleList
        }

        const childrenItems = await Promise.all(
          parentItem.item.additionalItems.map((item) => {
            return cloudStoreRepository.get.getItemPublishedDetail({
              id: item.id,
            })
          })
        )

        // 親アイテムと子アイテムを結合してプロフィールを取得
        state.value.bundleList = await getItemsWithProfile([
          parentItem,
          ...childrenItems,
        ])

        return state.value.bundleList
      } catch (e) {
        if (isFetchError(e)) {
          console.error(e)
        }
        throw e
      }
    }
  }

  // [販売者用] いいね一覧取得
  const getItemsLikes = async (_limit: number, page?: number) => {
    try {
      offset.value = page ? page * _limit - _limit : offset.value
      const response = await cloudStoreRepository.get.getItemsLikes({
        limit: _limit,
        offset: offset.value,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemsLikesResponse>(getItemsLikesResponse, response)
      ) {
        console.error('An API response is different.')
      }

      limit.value = _limit
      total.value = response.itemCount
      state.value.itemsLikesList = await getItemsWithProfile(response.items)
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  // ユーザープロフィールのデータを追加した購入履歴一覧を取得
  const purchaseHistoriesAndProfileMerge = async (
    items: OrderItemHistory[]
  ): Promise<OrderItemWithProfile[]> => {
    const vketIds = items
      .map((item) => item.user?.vketId)
      .filter((vketId): vketId is string => vketId !== undefined)
    if (vketIds.length === 0) return []
    const response = await profileRepository.post.getProfilesList({ vketIds })
    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetProfileListResponse>(getProfileListResponse, response)
    ) {
      console.error('An API response is different.')
    }

    const profiles = response.profiles
    const result = items.map((item) => {
      const profile = profiles.find(
        (_profile) => _profile.vketId === item.user?.vketId
      )
      return {
        ...item,
        profile: profile || null,
      }
    })
    return result
  }

  // 購入履歴一覧
  const getPurchaseHistories = async (_limit: number, page?: number) => {
    try {
      offset.value = page ? page * _limit - _limit : offset.value
      const response = await cloudStoreRepository.get.getPurchaseHistories({
        limit: _limit,
        offset: offset.value,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetPurchaseHistoriesResponse>(
          getPurchaseHistoriesResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      limit.value = _limit
      total.value = response.historyCount
      state.value.purchaseHistoriesList =
        await purchaseHistoriesAndProfileMerge(response.purchaseHistories)
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  // 購入履歴詳細
  const getPurchaseHistoriesDetail = async (
    params: GetPurchaseHistoriesDetailRequest
  ) => {
    try {
      const response =
        await cloudStoreRepository.get.getPurchaseHistoriesDetail(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetPurchaseHistoriesDetailResponse>(
          getPurchaseHistoriesDetailResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      limit.value = params.limit ?? 0
      offset.value = params.offset ?? 0
      state.value.purchaseHistoriesDetail = response.order
      total.value = response.order.itemsCount
      state.value.purchaseHistoriesDetailItems =
        await purchaseHistoriesAndProfileMerge(response.order.items)

      return response.order
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // 無償商品購入履歴詳細
  const getPurchaseHistoriesFreeDetail = async (
    params: GetPurchaseHistoriesFreeDetailRequest
  ) => {
    try {
      const response =
        await cloudStoreRepository.get.getPurchaseHistoriesFreeDetail(params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetPurchaseHistoriesFreeDetailResponse>(
          getPurchaseHistoriesFreeDetailResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      state.value.purchaseHistoriesFreeDetail = response.order

      const result = await profileRepository.get.getProfileById({
        vketId: response.order.item.user.vketId,
      })
      if (!result) {
        throw new Error('result is empty.')
      }

      state.value.purchaseHistoriesFreeDetailWithProfile = {
        ...response.order.item,
        profile: result.profile || null,
      }

      return response.order
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  const getForcedPrivateItems = async () => {
    const response = await cloudStoreRepository.get.getItemsForcedPrivate()
    if (!response) {
      throw new Error('response is empty.')
    }
    if (
      !existResponse<GetForcedPrivateItemsResponse>(
        getForcedPrivateItemsResponse,
        response
      )
    ) {
      console.error('An API response is different.')
    }

    state.value.forcedPrivateItems = response.forcedPrivateItems

    return response
  }

  // [販売者用] 販売商品登録
  const postItem = async (params: PostItemRequest) => {
    try {
      const response = await cloudStoreRepository.post.postItem(params)

      if (!existResponse<PostItemResponse>(postItemResponse, response)) {
        console.error('An API response is different.')
      }

      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [購入者用] 商品購入
  const postItemPurchase = async (id: string) => {
    try {
      const response = await cloudStoreRepository.post.postItemPurchase({ id })

      if (
        !existResponse<PostItemPurchaseResponse>(
          postItemPurchaseResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      if (state.value.assetDetail?.item) {
        state.value.assetDetail.item = response.item
        state.value.assetDetail.purchased = true
      }

      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [販売者用] 商品データ更新
  const putItem = async (params: PutItemRequest) => {
    try {
      const response = await cloudStoreRepository.put.putItem(params)
      if (!existResponse<PutItemResponse>(putItemResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.myAssetDetail = response.item
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // 「いいね」状態更新
  const changeLikeState = (
    response: Omit<ItemPurchasedWithProfile, 'profile'>
  ) => {
    if (state.value.assetDetail?.item.id === response.item.id) {
      state.value.assetDetail.item = response.item
      if (response.liked === undefined) {
        throw new Error('liked is empty.')
      }
      state.value.assetDetail.liked = response.liked
    }

    const updateList = (list: ItemPurchasedWithProfile[]) =>
      list.map((itemPurchased) => {
        if (itemPurchased?.item.id === response.item.id) {
          return {
            ...itemPurchased,
            item: response.item,
            liked: response.liked,
          }
        }
        return itemPurchased
      })

    state.value.popularList = updateList(state.value.popularList)
    state.value.officialList = updateList(state.value.officialList)
    state.value.bundleList = updateList(state.value.bundleList)
    state.value.avatarList = updateList(state.value.avatarList)
    state.value.housingList = updateList(state.value.housingList)
    state.value.pickupList = updateList(state.value.pickupList)
    state.value.vketBoothMarcheList = updateList(
      state.value.vketBoothMarcheList
    )
    state.value.avatarPartList = updateList(state.value.avatarPartList)
    state.value.assetList = updateList(state.value.assetList)
    state.value.otherList = updateList(state.value.otherList)
    state.value.categoryList = updateList(state.value.categoryList)
  }

  // [購入者用] 商品にいいね
  const putItemLike = async (id: string) => {
    try {
      const response = await cloudStoreRepository.put.putItemLike({ id })
      if (!existResponse<PutItemLikeResponse>(putItemLikeResponse, response)) {
        console.error('An API response is different.')
      }

      // 「いいね」状態更新
      changeLikeState(response)
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [販売者用] 商品データ更新
  const patchItem = async (params: PatchItemRequest) => {
    try {
      const { data } = await useAsyncData('patchItem', () =>
        cloudStoreRepository.patch.patchItem(params)
      )
      if (!existResponse<PatchItemResponse>(patchItemResponse, data.value)) {
        console.error('An API response is different.')
      }

      return data.value
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [購入者用] 商品にいいね取り消し
  const deleteItemLike = async (id: string) => {
    try {
      const response = await cloudStoreRepository.delete.deleteItemLike({ id })
      if (
        !existResponse<DeleteItemLikeResponse>(deleteItemLikeResponse, response)
      ) {
        console.error('An API response is different.')
      }

      // 「いいね」状態更新
      changeLikeState(response)
      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  // [購入者用] 公開商品一覧取得(ページング用)
  const getItemsPublishedWithPager = async (
    params: GetItemsPublishedRequestWithPager
  ) => {
    try {
      const _limit = params.limit || limit.value
      const _offset = params.page * _limit - _limit
      const options = params.options
      const objectTypeList: ObjectType[] = []
      if (options.isActivity) objectTypeList.push(OBJECT_TYPE.ACTIVITY)

      const _params: GetItemsPublishedRequest = {
        boothMakerRecommended: options.boothMakerRecommended,
        category: options.category,
        subCategory: options.subCategory,
        includeFreeItems: options.includeFreeItems,
        isOfficial: options.isOfficial,
        keyword: options.keyword,
        limit: _limit,
        offset: _offset,
        sort: options.sort,
        useVketChanParts: options.useVketChanParts,
        vketId: options.vketId,
        objectTypes: objectTypeList,
      }

      const response = await cloudStoreRepository.get.getItemsPublished(_params)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (
        !existResponse<GetItemsPublishedResponse>(
          getItemsPublishedResponse,
          response
        )
      ) {
        console.error('An API response is different.')
      }

      if (params.limit) limit.value = params.limit
      offset.value = _offset
      total.value = response.itemCount
      const itemsWithProfile = await getItemsWithProfile(response.items)
      state.value.assetList = itemsWithProfile

      return itemsWithProfile
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  /**
   * ソートタイプを更新する
   */
  const updateSortType = (type: SortType) => {
    sortType.value = type
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    sortType: readonly(sortType),
    sortItems,
    searchText,
    popularItemLimit,
    officialItemLimit,
    avatarItemLimit,
    furnitureItemLimit,
    pickupItemLimit,
    vketBoothMarcheItemLimit,
    avatarPartLimit,
    getItems,
    getItemDetail,
    getItemByAssetId,
    getItemsPublished,
    getItemsPublishedWithPager,
    getItemsPublishedRecent,
    getItemPublishedDetail,
    getItemsBundle,
    getItemsLikes,
    getEventItems,
    getItemsPopular,
    getPurchaseHistories,
    getPurchaseHistoriesDetail,
    getPurchaseHistoriesFreeDetail,
    getForcedPrivateItems,
    postItem,
    postItemPurchase,
    putItem,
    putItemLike,
    updateSortType,
    deleteItemLike,
  }
}

export type CloudStoreComposable = ReturnType<typeof useCloudStore>

export const cloudStoreInjectionKey: InjectionKey<CloudStoreComposable> =
  Symbol('cloud_store')
